<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','num']"
            :is_page="false"
            ref="list"
            rowKey="id"
            :submit_preprocessing="submit_preprocessing"
            @expandedRowsChange="handleExpandedRowsChange($event)"
            @list_after="list_after"
        >
            <template slot="right_btn">
				<a :href="'/manage/crm.export/launchReportExport' | export_form" v-if="this.$store.state.page_auth.export" target="_blank">
					<a-button  class="a_btn">导出</a-button>
				</a>
            </template>

		<!-- 	<template slot="form_after">
				<div class="page_content">
					<a-row type="flex">
						<a-col style="flex:1" v-for="item in info" :key="item.name" >
							<a-statistic :title="item.name" :value="item.value"></a-statistic>
						</a-col>
					</a-row>
				</div>
			</template> -->
            

            <template slot="num" slot-scope="data">
                <a @click="freezer(data.record)">{{data.text}}</a>
            </template>

            <template slot="operation" slot-scope="data">
                <a @click="see(data.record)">查看</a>
            </template>
        </TableList>

        <PopTableList
			width="1300px"
            rowKey="id"
            ref="pop_table_list"
            :is_created_get_list="false"
            :get_table_list="get_table_list_pop"
            :columns="columns_details"

            :visible.sync="visible"
        >
            
        </PopTableList>
    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { launchReport, getLaunchSub ,getLaunchInfo} from "@/api/goods";
import PopTableList from "@/components/PopTableList";


const columns = [
    {
        title: "部门",
        dataIndex: "name",
    },
    {
        title: "投放客户数",
        dataIndex: "customer_num",
        scopedSlots: {
            customRender: "customer_num"
        }
    },
	{
	    title: "投放品项数量",
	    dataIndex: "goods_num",
	    scopedSlots: {
	        customRender: "goods_num"
	    }
	},
    {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

const columns_details = [
    {
        title: "部门",
        dataIndex: "department",
	},
	{
        title: "投放人",
        dataIndex: "username",
	},
	{
        title: "客户编号",
        dataIndex: "customer_coding",
	},
	{
        title: "客户类型",
        dataIndex: "customer_type",
	},
	{
        title: "客户名称",
        dataIndex: "customer_name",
	},
	{
        title: "城/乡",
        dataIndex: "customer_district",
	},
	{
        title: "客户地址",
        dataIndex: "customer_address",
	},
	{
        title: "客户电话",
        dataIndex: "customer_phone",
	},
	{
        title: "投放类型",
        dataIndex: "investment_type",
	},
	{
        title: "投放品项",
        dataIndex: "investment_goods",
	},
	{
        title: "投放数量",
        dataIndex: "total",
	},
	{
        title: "投放时间",
        dataIndex: "investment_time",
    },
]



export default {
    name: "Index",
    components: {
        TableList,
        PopTableList
    },
    data() {
        return {
            get_table_list: launchReport,
            get_table_list_pop: getLaunchInfo,
            submit_preprocessing: {
                array_to_string: ['department','role']
            },
            columns,
            columns_details,
			visible: false,
			info:{},
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					// {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
					// },
					{
                        type: "text",
                        name: "customer",
                        title: "客户名称",
                        placeholder: '请输入客户名称',
                        options: {

                        }
					},
					{
                        type: "text",
                        name: "goods_title",
                        title: "品项名称",
                        placeholder: '请输入品项名称',
                        options: {

                        }
					},

					{
                        type: "range_date",
                        name: "range_date",
						title: "日期",
						// format: 'YYYY-MM',
						// range_mode: ['date', 'date'],
                        options: {
							
						},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
					},
					
					// {
                    //     type: "time_range",
                    //     name: "put_month",
                    //     title: "月份",
                    //     options: {

                    //     }
					// },
                ],

            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});
		this.$nextTick(()=>{
			this.get_info();
		})

		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role") {
        //             item.list = [...this.$config.status_all, ...res];
        //         }
        //     });
		// });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role") {
					item.treeData = res;
				}
			});
		});

    },
    methods: {
        get_list_key(list, key, index_arr = []) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == key) {
                    return list[i]
                }

                if (Array.isArray(list[i].children) && list[i].children.length) {
                    let value = this.get_list_key(list[i].children, key);
                    if (value !== false) {
                        return value;
                    }
                }
            }

            return false;
		},
		get_info(){
			// census({
			// 	...this.$refs.list.seo_data
			// }).then(res=>{
			// 	this.info = res.data;
			// })
		},
        see(record) {
            this.visible = true;
            this.$nextTick(res => {
                this.$refs.pop_table_list.set_data('fixed_seo_data', {
                    department_id: record.id,
                    ...this.$refs.list.seo_data
                })
                this.$refs.pop_table_list.get_list();
            })
        },
        async handleExpandedRowsChange(e) {
            e.forEach(key => {
                let list = JSON.parse(JSON.stringify(this.$refs.list.list));

                let item = this.get_list_key(list, key);

                if (!item.hasOwnProperty('is_req')) {
                    getLaunchSub({
                        data: {
                            department_id: key,
                            ...this.$refs.list.seo_data
                        }
                    }).then(res => {

                        item.children = [
                            ...res.data.list.map((vo) => {
                               if (vo.type == 2) {
									vo.children = []
                               };
                                return vo;
                            })

                        ];
                        item.is_req = true;
                        this.$refs.list.set_data('list', list);
                    })
                }
            });
        },

        release(record) {
            this.$router.push({
                path: "/good_list/release",
                query: {
                    department: record.id ? record.id : record.department_id,
                    keyword: record.goods_model ? record.goods_model : '',
                }
            });
        },

        list_after(res) {
            let list = this.$refs.list.list;
            list = list.map((item, index) => {
                item.children = [];
                return item;
            });

        },

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.visit_img {
    width: 60px;
    height: 60px;
}
</style>